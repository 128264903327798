import * as React from "react"
import Layout from "../components/layout"

import {Link} from "gatsby"

const NotFoundPage = () => {
  return (

    <Layout>

      <div className="site-wrapper-reveal border-bottom">

        <div className="error-page-area section-space--ptb_90">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 ml-auto mr-auto">
                <div className="error-page-content text-center section-space--ptb_90">
                  <i className="icon icon-confused"></i>
                  <h1 className="mb-20">ohh! page not found</h1>
                  <p className="description">It seems we can't find what you're looking for.
                    Perhaps searching can help or go back to 
                    <Link to="/"> Homepage</Link>
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </Layout>

  )
}

export default NotFoundPage
